
<section id="demos" class="flex flex-col items-center" *ngIf="bodySegmentationService.isReady$ | async; else loading">
  <ng-container *ngIf="viewMode === 'image'">
    <div class="flex justify-around">
      <div class="sample-image relative flex-1">
        <img alt="process" class="object-contain" [src]="imageUrl" (load)='onImageClick($event)' crossorigin="anonymous" />
        <canvas #imageCanvas></canvas>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="viewMode === 'webcam'">
    <div class="w-full h-full flex flex-col items-center black-bg">
      <span *ngIf="!hasGetUserMedia()" class="text-center text-3xl">Webcam is not supported by your browser</span>
      <!--<button *ngIf="hasGetUserMedia() && !videoRenderCanvas" class="btn" (click)="enableCam()">Enable Webcam</button>-->
      <div *ngIf="hasGetUserMedia() && !videoRenderCanvas"
           class="sample-image relative flex-1">
        <img alt="process" class="object-contain" [src]="selfieImagePath" crossorigin="anonymous" (click)="enableCam()" />
      </div>
      <div class="webcam relative flex-1">
        <video loop muted playsinline #webcam id="myVideo" autoplay></video>
        <canvas #webcamCanvas class="overlay"></canvas>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #loading>
  <div class="my-8 text-center text-3xl">Loading the Machine Learning model...</div>
</ng-template>
